import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "../components/common/ScrollToTop";
import RequestDemo from "../pages/RequestDemo";
import HomeNine from "../themes/index9/HomeNine";


class Routes extends Component {
  render() {
    return (
      <>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={HomeNine} />
            
            <Route exact path="/request-for-quote" component={RequestDemo} />
            />
          </Switch>
        </Router>
      </>
    );
  }
}
export default Routes;
