import React from "react";

const ServicePromo = () => {
  return (
    <section className="services-icon ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-heading text-center">
              <h2>Services</h2>
              <p>
              Everything you need to support your digital transformation strategy. Right here!
              </p>
            </div>
            ,
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
              <div className="service-icon icon-center">
                <img
                  src="assets/img/service/coding.png"
                  alt="service icon"
                  width="65"
                  height="65"
                />
              </div>
              <div className="service-info-wrap">
                <h3 className="h5">Web Development</h3>
                <p>
                Responsive & user friendly Web applications which runs on any browser and utilizing most recent technologies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
              <div className="service-icon icon-center">
                <img
                  src="assets/img/service/app-development.png"
                  alt="service icon"
                  width="65"
                  height="65"
                />
              </div>
              <div className="service-info-wrap">
                <h3 className="h5">App Development</h3>
                <p>
                We provide a full-stack mobile application development service on your budget and as per the stipulated timeframe.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom">
              <div className="service-icon icon-center">
                <img
                  src="assets/img/service/shield.png"
                  alt="service icon"
                  width="65"
                  height="65"
                />
              </div>
              <div className="service-info-wrap">
                <h3 className="h5">Data Security</h3>
                <p>
                Protect critical business data from undesired access, and secure data from ransomware and theft.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center border-end">
              <div className="service-icon icon-center">
                <img
                  src="assets/img/service/curve.png"
                  alt="service icon"
                  width="65"
                  height="65"
                />
              </div>
              <div className="feature-info-wrap">
                <h3 className="h5">UI/UX Design</h3>
                <p>
                Our creative staff can create appealing and pleasant design solutions as per the client’s requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center border-end">
              <div className="service-icon icon-center">
                <img
                  src="assets/img/service/graphic-design.png"
                  alt="service icon"
                  width="65"
                  height="65"
                />
              </div>
              <div className="feature-info-wrap">
                <h3 className="h5">Graphics Design</h3>
                <p>
                Get the perfect logo and ensure your branding makes a lasting impression.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-0">
            <div className="single-service p-lg-5 p-4 text-center">
              <div className="service-icon icon-center">
                <img
                  src="assets/img/service/promotion.png"
                  alt="service icon"
                  width="65"
                  height="65"
                />
              </div>
              <div className="feature-info-wrap">
                <h3 className="h5">Digital Marketing</h3>
                <p>
                Attract new clients with organic search engine optimization (SEO) and online marketing. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicePromo;
