import React from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_4ss487v', 'template_03tao6d', e.target, 'yAaKinZ2VqmhAn7dQ').then(res=>{
      console.log(res);
    }).catch(err=> console.log(err));
  }
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='assets/img/logo-white.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='assets/img/logo-color.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  <p>
                    Our latest news, articles, and resources will be sent to
                    your inbox regularly.
                  </p>

                  <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex' onSubmit={sendEmail}>
                    <input
                      type='email'
                      className='input-newsletter form-control me-2'
                      placeholder='Enter your email'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form>
                  
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Address</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Pinetree Plaza
                          </Link>
                        </li>
                        <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            Ngong Road
                          </Link>
                        </li>
                        <li>
                          <Link to='/services' className='text-decoration-none'>
                            Nairobi
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Phone</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/pricing' className='text-decoration-none'>
                            +254(0)743 802 806
                          </Link>
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Email</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/contact-us'
                            className='text-decoration-none'
                          >
                            info@aziri.co.ke
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; 2022 Aziri Technologies | All Rights Reserved.
                   
                  </p>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
