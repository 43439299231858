import React from 'react';

const ReviewTwo = ({ reqPage }) => {
  return (
    <>
      <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
        <div className='testimonial-tab-slider-wrap mt-5'>
          {reqPage ? (
            <h1 className='fw-bold text-white display-5'>
            Get In Touch
            </h1>
          ) : (
            <h2 className='text-white'>Want to discuss your project?</h2>
          )}
          <p>
          Whatever your enquiry is, one of us will get in touch with you.
          </p>
          <hr />
          <div
            className='tab-content testimonial-tab-content text-white-80'
            id='pills-tabContent'
          >
            <div
              className='tab-pane fade show active'
              id='testimonial-tab-1'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  
                </em>
              </blockquote>
              <div className='author-info mt-3'>
                <span className='h6'>Contact</span>
                <span className='d-block small'>Email: info@aziri.co.ke</span>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-2'
              role='tabpanel'
            >
              
              
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-3'
              role='tabpanel'
            >
             
              
              
            </div>
          </div>
          <ul
            className='nav nav-pills mb-0 testimonial-tab-indicator mt-5'
            id='pills-tab'
            role='tablist'
          >
           
       
            
          </ul>
        </div>
      </div>
    </>
  );
};

export default ReviewTwo;
