import AboutImage from "../../components/about/AboutImage";

import PageMeta from "../../components/common/PageMeta";


import Portfolio from "../../components/portfolio/Portfolio";

import ServicePromo from "../../components/promo/ServicePromo";
import TechTab from "../../components/tabs/TechTab";

import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroNine from "./HeroNine";

const HomeNine = () => {
  return (
    <Layout>
      <PageMeta title="Aziri- Software &amp; IT Solutions Provider" />
      <Navbar />
      <HeroNine />
      <AboutImage />
      <TechTab />
      <ServicePromo />
      <Portfolio />
      
      
  
      
      <FooterOne />
    </Layout>
  );
};

export default HomeNine;
