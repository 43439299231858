import React from 'react';
import { Link } from 'react-router-dom';

const FooterSocial = () => {
  return (
    <>
      <footer className='footer-section'>
        {/* <!--footer bottom start--> */}
        <div className='footer-bottom bg-dark text-white py-4'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; 2022 Aziri Technologies | All Rights Reserved. 
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {/* <!--footer bottom end--> */}
      </footer>
    </>
  );
};

export default FooterSocial;
