import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <section className="portfolio bg-light ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10">
            <div className="section-heading text-center">
              <h2>Over 100+ Companies Trusted Us</h2>
              <p>We have completed over 160 projects</p>
            </div>
          </div>
        </div>
        <div className="row">
          <ul className="brand-logo-grid list-unstyled">
            <li>
              <img src="assets/img/brand-logo/1-2.png" alt="brand logo" />
            </li>
            <li>
              <img src="assets/img/brand-logo/2-1.png" alt="brand logo" />
            </li>
            <li>
              <img src="assets/img/brand-logo/3-1.png" alt="brand logo" />
            </li>
            <li>
              <img src="assets/img/brand-logo/4-1.png" alt="brand logo" />
            </li>
            <li>
              <img src="assets/img/brand-logo/5-1.png" alt="brand logo" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
