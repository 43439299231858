import React from "react";
import { Link } from "react-router-dom";

const TechTab = () => {
  return (
    <section className="ptb-120 bg-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6">
            <div className="text-center">
              <h2>Turn ideas into products</h2>
              <p>
              Whether you are an early stage startup, or are looking at growth, scaling or transformation, we can help you take your idea and product to the next level with our technical expertise.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="service-tabs">
              <ul
                className="nav nav-pills d-flex justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active me-4"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <i className="fas fa-desktop me-3"></i>
                    <span>Web Design</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link me-4"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <i className="fas fa-tablet-alt me-3"></i>
                    <span>Software Development</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    <i className="fas fa-vector-square me-2"></i>
                    <span>App</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="tab-content-wrapper pt-60">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="text-center mb-5 mb-lg-0">
                      <img
                        src="assets/img/tab_img1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tab-right-content">
                      <h2>
                      Intuitive and elegant design <br />
                      for your website
                      </h2>
                      <p>
                      By blending modern technology and creativity, our designers can create a compelling user interface and enhanced user experience while using innovative approaches.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Responsive Design Development</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Information Architecture Design</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Wire-framing</span>
                        </li>
                      </ul>
                      <Link
                        to="/about-us"
                        className="text-white link-with-icon text-decoration-none mt-4"
                      >
                        Get a free assessment
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="tab-content-wrapper pt-60">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="pe-5 mb-5 mb-lg-0">
                      <img
                        src="assets/img/tab_img2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tab-right-content">
                      <h2>Custom Software Development</h2>
                      <p>
                      We develop robust, secured, and high-performing software solution that provides the best user experience.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Enterprise Software Solution</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Testing and QA service</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Maintenance and Support</span>
                        </li>
                      </ul>
                      <Link
                        to="/about-us"
                        className="text-white link-with-icon text-decoration-none mt-4"
                      >
                        Contact us for quote
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="tab-content-wrapper pt-60">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="mb-5 mb-lg-0">
                      <img
                        src="assets/img/tab_img3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tab-right-content">
                      <h2>Mobile App Development </h2>
                      <p>
                      From architecting ideas to final deployment on play store or app store, we smoothen up the lifecycle process. Our mobile app team can develop a cutting-edge solution as per your requirements, whether you want to build a native app or hybrid app.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Design and Prototyping</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Custom Mobile App Development</span>
                        </li>
                        <li>
                          <i className="fas fa-check text-primary"></i>
                          <span>Mobile App Testing</span>
                        </li>
                      </ul>
                      <Link
                        to="/about-us"
                        className="text-white link-with-icon text-decoration-none mt-4"
                      >
                        Request a quote
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechTab;
