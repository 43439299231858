import React from "react";
import { Link } from "react-router-dom";

const AboutImage = () => {
  return (
    <section className="ptb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-left text-lg-center mb-32 mb-lg-0">
              <img src="assets/img/about.jpg" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-right">
              <h4 className="text-primary h5 mb-3">Why Choose Us</h4>
              <h2 className="mb-4">
                We bring you <br />
                results
              </h2>
              <p>
              We help you achieve your goals while bringing cost and time savings for your business without having to take on overhead management.
              </p>
              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/pie-chart.png" alt="" />
                    </div>
                    <div>
                      <h5>Track record</h5>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/team.png" alt="" />
                    </div>
                    <div>
                      <h5>Top talent</h5>
                    </div>
                  </div>
                </li>
              </ul>

              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  10+ years experience
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Agile/DevOPS Methodology
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                   Milestone wise payment
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Delivery on time
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Free bug solving post project delivery
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Committed developers
                </li>
              </ul>
              <Link
                to="/request-for-quote"
                className="link-with-icon text-decoration-none mt-3 btn btn-primary"
              >
                Get a free assessment
                <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutImage;
